import { useCallback } from 'react';

import { App } from '../constants/app';

export type SegmentPageSchema = {
  category?: string;
  name?: string;
  properties?: unknown;
  options?: SegmentAnalytics.SegmentOpts;
};

export const useSegmentPage = () => {
  const execute = (
    {
      category,
      name,
      options,
      properties,
    }: SegmentPageSchema = {}) => {
    const segmentInstance = window[App.SegmentAnalyticsWindowKey];

    if (!segmentInstance) {
      return;
    }

    /*
      Allow for passing of a name property and other segment page properties
      https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/segment-analytics/index.d.ts#L119
      https://segment.com/docs/connections/spec/page/
    */

    segmentInstance.page(name, category, properties, options);
  };

  return {
    execute: useCallback(execute, []),
  };
};
