export enum SegmentEvents {
  // Auth flow
  MarketplaceSignInCreateAccount = 'Marketplace - Sign In/Create Account',
  MarketplaceSignIn = 'Marketplace - Sign In',
  MarketplaceCodeVerification = 'Marketplace - Code Verification',
  MarketplaceCreateAccount = 'Marketplace - Create Account',

  // Initial request type
  MarketplaceRequestASitting = 'Marketplace - Request a Sitting',
  MarketplaceRequestABoarding = 'Marketplace - Request a Boarding',

  // Pet info
  MarketplaceAddPet = 'Marketplace - Add Pet',
  MarketplaceTellUsAboutYourPet = 'Marketplace - Tell Us About Your Pet',

  // Requirements
  MarketplaceReviewBoardingRequirements = 'Marketplace - Review Boarding Requirements',
  MarketplaceReviewSittingRequirements = 'Marketplace - Review Sitting Requirements',

  // Preferences
  MarketplaceBoardingPreferences = 'Marketplace - Boarding Preferences',
  MarketplaceSittingPreferences = 'Marketplace - Sitting Preferences',
  MarketplaceCaregiverPreferences = 'Marketplace - Caregiver Preferences',
  MarketplaceHowShouldTheCaregiverEnterYourHome = 'Marketplace - How should the caregiver enter your home?',

  // Payment and confirmation
  MarketplaceAddPaymentCard = 'Marketplace - Add Payment Card',
  MarketplaceRequestSummary = 'Marketplace - Request Summary',
  MarketplaceBookingConfirmation = 'Marketplace - Booking Confirmation',

  // Standard Events
  LandingPageClick = 'Landing Page Click',
  LandingPageView = 'Landing Page View',
}