import { add } from 'date-fns';

export const checkExpiration = (createdAt: number) => {
  const now = new Date().getTime();
  const isExpired = now > add(createdAt, { days: 1 }).getTime();

  return {
    isExpired,
  };
};
