import clsx from 'clsx';
import React from 'react';
import { FilterTitle } from './FilterTitle';
import { PetsPicker } from './PetsPicker';
type Props = {
  fullWidthAll?: boolean;
  titleClassName?: string;
  triggerButtonClass?: string;
  title?: string;
  iconColor?: string;
  containerClass?: string;
};
export const PetsFilters = ({
  fullWidthAll,
  titleClassName,
  triggerButtonClass,
  title = 'Pets',
  iconColor,
  containerClass
}: Props) => {
  return <div className={clsx('flex justify-between sm:ml-auto md:ml-0', containerClass)} data-sentry-component="PetsFilters" data-sentry-source-file="PetsFilters.tsx">
      <div className={clsx(fullWidthAll ? 'w-full' : 'w-full md:w-[130px]')}>
        <FilterTitle title={title} className={titleClassName} data-sentry-element="FilterTitle" data-sentry-source-file="PetsFilters.tsx" />
        <PetsPicker triggerButtonClass={triggerButtonClass} iconColor={iconColor} data-sentry-element="PetsPicker" data-sentry-source-file="PetsFilters.tsx" />
      </div>
    </div>;
};