import React from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { BookingFilters } from './BookingFilters';
import { BookingTagline } from './Tagline';
import { HeroTitle, HeroTitleProps } from './HeroTitle';
import { HeroImage } from './HeroImage';
export type LandingHeroProps = {
  heroDesktopImg?: string;
  heroMobileImg?: string;
  heroTitle?: string;
  taglineLink?: string;
  tagline?: string;
  taglineText?: string;
  heroTitleProps?: HeroTitleProps;
};
type Props = {
  heroProps?: LandingHeroProps;
};
export const Hero = ({
  heroProps
}: Props) => {
  return <div className={clsx('relative w-full', 'mb-[40px] sm:mb-[50px] md:mb-[150px] lg:mb-[180px] xl:mb-[24vh] 2xl:mb-[32vh]')} data-sentry-component="Hero" data-sentry-source-file="index.tsx">
      <HeroImage heroMobileImg={heroProps?.heroMobileImg} heroDesktopImg={heroProps?.heroDesktopImg} data-sentry-element="HeroImage" data-sentry-source-file="index.tsx" />
      <div className={clsx('relative max-w-[1150px] w-[96%] sm:w-[92%] md:w-[96%] lg:w-full px-5 mx-auto', 'pt-[40px] sm:pt-[90px] md:pt-[50px] lg:pt-[2.5vw] xl:pt-[1vw]')}>
        <HeroTitle containerClassName="max-w-[1000px] md:mb-8 lg:mb-[0px] lg:mt-[30px] 2xl:mt-[3vw] 2xl:mb-[3vw]" {...heroProps.heroTitleProps} data-sentry-element="HeroTitle" data-sentry-source-file="index.tsx" />
        
        <BookingFilters data-sentry-element="BookingFilters" data-sentry-source-file="index.tsx" />

        <BookingTagline containerClassName={clsx('mt-5 md:mt-7 lg:mt-6 md:!text-left w-full max-w-[1000px] mx-auto !text-sm')} data-sentry-element="BookingTagline" data-sentry-source-file="index.tsx">
          {heroProps?.taglineText}
          {heroProps.taglineLink && <Link aria-label="Pet Sitting" href={heroProps.taglineLink} legacyBehavior>
              <span className={clsx('underline ml-1 text-wagBlue md:text-white cursor-pointer')}>
                {heroProps?.tagline}
              </span>
            </Link>}
          {' '}
          services.
        </BookingTagline>
      </div>
    </div>;
};