'use client';

import React from 'react';
import clsx from 'clsx';
import { SectionContainer } from '../components/SectionContainer';
import { SectionTitle } from '../components/SectionTitle';
import { localExportItems } from './data';
import { FindCaregiverButton } from '../hero/FindCaregiverButton';
import CheckmarkCircle from '../../../assets/checkmarkCircle.svg';
import { IS_BROWSER } from '../../BoneSpinner';
import { PageServiceTypes } from '..';
import { CaregiverMediaSection } from './CaregiverMediaSection';
export type LocalExpertProps = {
  serviceType?: PageServiceTypes;
  localExpertTitle?: string;
};
export const LocalExpert = ({
  serviceType,
  localExpertTitle = 'Pet care provided by local experts you can trust'
}: LocalExpertProps) => <SectionContainer className="!max-w-[1150px] w-[90%] md:w-full" data-sentry-element="SectionContainer" data-sentry-component="LocalExpert" data-sentry-source-file="index.tsx">
    <div className={clsx('relative h-full md:h-[650px] rounded-[18px] overflow-hidden lg:h-[560px]', ' mx-auto shadow-[0px_3px_10px_-1px_rgba(0,0,0,0.1)]')}>
      <div className="md:w-1/2 pb-[420px] sm:pb-[600px] p-[26px] md:p-8 lg:p-12 md:!pr-0 flex flex-col justify-between h-full">
        <SectionTitle text={localExpertTitle} className="mb-4 md:!mb-[20px] md:!text-left lg:!mb-[20px]" data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
        <p className="text-center md:text-left mb-[10px] md:mb-0 lg:mb-[10xp] mt-0 text-wrap-balance">
          All Wag! Pet Caregivers have had extensive background checks,
          so you don’t have to worry about a thing
        </p>
        <div className="mt-[20px] mb-auto">
          {localExportItems.map(item => <div key={item.description} className="flex items-start mb-6 md:mb-3 lg:mb-4 md:items-center">
              <img src={CheckmarkCircle} loading="lazy" alt="checkmark" className="w-[24px] h-[24px] mr-3 mt-[3px] md:mt-0" />
              <p className="align-top">
                {serviceType === 'Sitting' ? item.description.replace('Boarders', 'Sitters') : item.description}
              </p>
            </div>)}
        </div>

        <FindCaregiverButton containerClassName="ml-0 mt-2 md:mb-0 md:px-10 max-w-[500px]" tooltipTriggerClassName="mx-auto w-[300px] md:ml-0" onClick={() => {
        const bookingFilters = document.querySelector<HTMLDivElement>('#booking-filters');
        if (bookingFilters && IS_BROWSER) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        } else {
          const modalTriggerButton = document.querySelector<HTMLButtonElement>('#filters-modal-trigger');
          if (modalTriggerButton) {
            modalTriggerButton.click();
          }
        }
      }} data-sentry-element="FindCaregiverButton" data-sentry-source-file="index.tsx" />
      </div>
      <CaregiverMediaSection data-sentry-element="CaregiverMediaSection" data-sentry-source-file="index.tsx" />
    </div>
  </SectionContainer>;