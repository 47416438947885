import { useSegmentTrack, BookingJourneySegmentTrack } from '@/hooks/useSegmentTrack';
import { SegmentEvents } from '@/constants/segment-events';

export const useSegmentEvent = () => {
  const { executeTrack } = useSegmentTrack();

  const trackEvent = (event: SegmentEvents, properties: Record<string, any> = {}) => {
    executeTrack<BookingJourneySegmentTrack>({
      data: {
        event,
        properties
      },
    });
  };

  return {
    trackEvent
  };
};