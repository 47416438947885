import React from 'react';
import { cn } from '../../../../../utils/tailwind/cn';
import { SectionContainer } from '../components/SectionContainer';
import { SectionTitle } from '../components/SectionTitle';
export type ServiceDescriptionProps = {
  title?: string;
  description?: string;
  containerClassName?: string;
};
export const ServiceDescription = ({
  title,
  description,
  containerClassName
}: ServiceDescriptionProps) => <SectionContainer className={cn('w-[90%] mb-0 md:mb-[40px] lg:mb-[50px]', containerClassName)} data-sentry-element="SectionContainer" data-sentry-component="ServiceDescription" data-sentry-source-file="index.tsx">
    <SectionTitle text={title} className="mb-4 md:!mb-[20px] md:text-center" data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
    <p className="text-wagGray md:text-center mt-6 font-[400] max-w-[750px] mx-auto">{description}</p>
  </SectionContainer>;