'use client';

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { AxiosError } from 'axios';
import { parseISO } from 'date-fns';
import { getWalkerProfiles } from '../../../../../hooks/api/getWalkerProfiles';
import { useAuthStore, useBookingStore, useFiltersStore } from '../../../context';
import { FindCaregiverButton } from './FindCaregiverButton';
import { DateFilters } from './filters/DateFilters';
import { FilterTitle } from './filters/FilterTitle';
import { WalkerProfileList } from '../../whippet/hero';
import { validateLandingFilters } from './validation';
import { SearchCombobox } from '../../booking-journey/components/combos/SearchCombobox';
import { useResetJourney } from '../../../hooks/useResetJourney';
import { checkExpiration } from '../../../helpers/checkExpiration';
import { PetsFilters } from './filters/PetsFilters';
import { fillStrategy } from '@/containers/BookingJourney/types/estimatedPrice';
import { useSegmentEvent } from '@/hooks/useSegmentEvent';
import { SegmentEvents } from '@/constants/segment-events';
type Props = {
  containerClassName?: string;
  isModal?: boolean;
};
const defaultClassName = 'md:flex flex-col bg-white md:scroll-mt-[200px] md:shadow-md';
const modalClassName = 'flex flex-col bg-grayBg';
export const BookingFilters = ({
  containerClassName,
  isModal = false
}: Props) => {
  const router = useRouter();
  const filters = useFiltersStore(state => state.filters);
  const setJourney = useBookingStore(state => state.setJourney);
  const {
    selectedDays
  } = useBookingStore(state => state.schedule);
  const sessionToken = useAuthStore(state => state.session.sessionToken);
  const setSchedule = useBookingStore(state => state.setSchedule);
  const isOnDemand = useBookingStore(state => state.isOnDemand);
  const isMarketplaceEnabled = useBookingStore(state => state.isMarketplaceEnabled);
  const {
    trackEvent
  } = useSegmentEvent();
  const parsedFilters = validateLandingFilters({
    ...filters,
    selectedDays
  });
  const createdAt = useBookingStore(state => state.createdAt);
  const {
    resetBookingJourneyAndClearStorage,
    resetAuthState
  } = useResetJourney();
  const {
    refetch,
    isFetching
  } = useQuery<WalkerProfileList, AxiosError>({
    queryKey: ['caregiver-profiles', filters],
    queryFn: () => getWalkerProfiles({
      longitude: filters.longitude,
      latitude: filters.latitude,
      perPage: 20,
      datetimeMin: filters.startDate ? parseISO(filters.startDate).toISOString() : null,
      datetimeMax: filters.endDate ? parseISO(filters.endDate).toISOString() : null,
      service: filters?.service,
      walkerProfileFlags: filters.walkerProfileFlags
    }, {
      maxDistance: true
    }),
    enabled: false
  });
  const getNavigationUrl = () => {
    if (isMarketplaceEnabled && !isOnDemand) {
      return filters.service === 'boarding' ? '/marketplace-boarding' : '/marketplace-sitting';
    }
    if (isOnDemand) {
      return '/on-demand';
    }
    return '/search';
  };
  const handleNavigation = async () => {
    if (!parsedFilters.success) {
      return;
    }
    const {
      isExpired
    } = checkExpiration(createdAt);
    if (isExpired) {
      resetAuthState();
      resetBookingJourneyAndClearStorage();
    }

    // Track the appropriate event based on the service and landing type
    const getSegmentEvent = () => {
      const serviceCategories = {
        sitting: 'overnight',
        boarding: 'overnight',
        walk: 'walk',
        'drop-in': 'drop_in'
      } as const;
      const properties = {
        initial_service_type: filters.service,
        initial_service_category: serviceCategories[filters.service as keyof typeof serviceCategories] ?? 'unknown',
        pet_type: router.pathname === '/cat-sitting' ? 'cat' : 'dog'
      };
      return {
        event: SegmentEvents.LandingPageClick,
        properties
      };
    };
    const segmentEvent = getSegmentEvent();
    if (segmentEvent) {
      trackEvent(segmentEvent.event, segmentEvent.properties);
    }
    if (isOnDemand || isMarketplaceEnabled) {
      setSchedule({
        fillStrategy: isMarketplaceEnabled ? fillStrategy.pet_parent_choice : fillStrategy.use_fastest_available
      });
      setJourney({
        step: sessionToken && !isExpired ? 'RequestService' : 'CreateAccount'
      });
    } else {
      await refetch();
    }
    router.push(getNavigationUrl());
  };
  return <div id="booking-filters" className={clsx('shadow-[0px_8px_24px_0px_#959DA533] border md:shadow-none md:border-0', 'md:pr-1.5 py-0 md:pl-6 lg:pl-8 w-full md:border border-gray200', 'max-w-[400px] md:max-w-[1000px] mx-auto scroll-mt-6', !isModal && 'rounded-3xl md:rounded-full mt-6', isModal ? modalClassName : defaultClassName, 'transition-all duration-100 ease-in-out', containerClassName)} data-sentry-component="BookingFilters" data-sentry-source-file="BookingFilters.tsx">
      <div className="transition-[height] duration-100 ease-in-out">
        <div className={clsx('flex flex-col md:flex-row w-full justify-between sm:items-center gap-y-0 gap-x-4', 'px-0 py-1 md:px-1', isModal ? 'flex-col mb-0 space-y-2 md:space-y-4' : 'mb-0')}>
          <div className="w-full md:w-[420px] lg:w-[930px] flex items-center flex-col px-4 pt-3 lg:px-0 lg:pt-0">
            <FilterTitle title="Location" className='relative top-1 z-9 font-[400]' data-sentry-element="FilterTitle" data-sentry-source-file="BookingFilters.tsx" />
            <SearchCombobox height='40px' inputClassName='!text-[16px] border-none px-7 focus:outline-none focus:ring-0 focus:border-transparent' locatorClassName='!static' data-sentry-element="SearchCombobox" data-sentry-source-file="BookingFilters.tsx" />
          </div>
          <div className="hidden md:block w-1 h-12 border-r border-r-gray200 opacity-80"></div>
          <div className="md:hidden w-full h-1 border-b border-b-gray-200 opacity-80"></div>
          <DateFilters isModal={isModal} containerClass='w-full px-4 md:px-0 md:w-fit' titleClassName='relative top-3 z-9 font-[400]' showArrow={true} data-sentry-element="DateFilters" data-sentry-source-file="BookingFilters.tsx" />
          <div className="hidden md:block w-1 h-12 border-r border-r-gray200 opacity-80"></div>
          <div className="md:hidden w-full h-1 border-b border-b-gray-200 opacity-80"></div>
          <PetsFilters titleClassName='relative top-3 z-9 font-[400]' containerClass='w-full px-4 md:w-fit md:px-0' data-sentry-element="PetsFilters" data-sentry-source-file="BookingFilters.tsx" />
          <div className="md:hidden w-full h-1 border-b border-b-gray-200 opacity-80"></div>
          <FindCaregiverButton cySelector="submit" searchText='Search' tooltipText="Please complete all search fields to find a Caregiver" tooltipTriggerClassName="w-full mx-auto lg:mr-0 md:ml-auto md:w-max lg:w-1/3" containerClassName={clsx('sm:w-auto mt-0 max-w-[500px] md:py-[19px]', 'm-4 md:m-0')} isDisabled={!parsedFilters.success || isFetching} onClick={handleNavigation} data-sentry-element="FindCaregiverButton" data-sentry-source-file="BookingFilters.tsx" />
        </div>
      </div>
    </div>;
};