export const localExportItems = [
  {
    description: 'Search from hundreds of Pet Boarders local to you',
  },
  {
    description: 'Choose the best match from detailed Caregiver profiles',
  },
  {
    description: 'Caregivers are tested to handle dogs in real-life scenarios',
  },
  {
    description: 'Download the Wag! app for GPS tracking',
  },
  {
    description: 'Use in-app messaging for all-important pet updates!',
  },
];
