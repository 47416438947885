import Link from 'next/link';
import React from 'react';
import { cn } from '../../../../../utils/tailwind/cn';
import CatIcon from '../../../assets/cat-icon.svg';
export const OvernightLinks = () => <p className="text-center" data-sentry-component="OvernightLinks" data-sentry-source-file="links.tsx">
    For overnight care at your home,
    <br className="md:hidden" />
    <Link aria-label="Cat Sitting" href="/dog-boarding" className="ml-[2px] underline" data-sentry-element="Link" data-sentry-source-file="links.tsx">
      try Boarding with Wag!
    </Link>
  </p>;
export const CatLinks = () => <div className="flex items-center justify-center" data-sentry-component="CatLinks" data-sentry-source-file="links.tsx">
    <div className="p-2 w-fit bg-caribbeanGreen rounded-full mr-2">
      <img src={CatIcon} alt="Cat Icon" className={cn('w-[26px] h-[26px] mx-auto')} />
    </div>
    <Link aria-label="Cat Sitting" href="/cat-sitting" className="text-caribbeanGreen font-[600] underline ml-4" data-sentry-element="Link" data-sentry-source-file="links.tsx">
      Cat Sitting
    </Link>
  </div>;
export const PetWalkingLinks = () => <p className="text-center" data-sentry-component="PetWalkingLinks" data-sentry-source-file="links.tsx">
    Looking for a caregiver to take your pet for a walk? 
    <br className="md:hidden" />
    <Link aria-label="Cat Sitting" href="/dog-walking" className="ml-[2px] underline" data-sentry-element="Link" data-sentry-source-file="links.tsx">
      Pet Walking services are available
    </Link>
  </p>;