/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { ServiceType } from '../../../types/api/walkerV6';

export type V4_200_Error = {
  success?: boolean;
  error?: string;
  error_code?: number;
  messages?: Record<string, string[]>;
}

export const fillStrategy = {
  none: 0,
  use_fastest_available: 1, // Quick Match
  use_best_available: 2,
  on_demand: 3, // ASAP
  use_preferred: 4,
  pet_parent_choice: 5, // Pet Parent Match,
};

export type FillStrategy =
| 'none'
| 'use_fastest_available'
| 'use_best_available'
| 'on_demand'
| 'use_preferred'
| 'pet_parent_choice';

export type EstimatedPriceComponent = {
  canBeDiscounted: boolean;
  description: string;
  quantity: number;
  tag: string;
  type: string;
  unitPriceInCents: number;
}

export type EstimatedPrice = {
  estimated_price_range_id: number;
  estimated_price_range_uuid: string;
  components: EstimatedPriceComponent[];
  min_expected_total_price: number;
  mid_expected_total_price: number;
  max_expected_total_price: number;
}

export type EstimatedPriceQueryParams = {
  serviceType: ServiceType;
  dogCount: number;
  fillStrategy?: FillStrategy;
  startDateTime?: string;
  endDateTime?: string;
  hasPickupAndDropoff?: boolean;
  sitterShouldStayOver?: boolean;
}

export type GetEstimatedPriceResponse = AxiosResponse<EstimatedPrice>;

export type EstimatedPriceLineItem = {
  can_be_discounted: boolean;
  description: string;
  max_expected_unit_price_in_cents: number;
  min_expected_unit_price_in_cents: number;
  quantity: number;
  tag: string;
  type: string;
  unit_price_in_cents: number;
}

type SuggestedTips = {
  percentage: number;
  tipAmount: number;
}

export type EstimatedPriceV4 = {
  description: string;
  estimated_price_range_id: number;
  has_estimated_price_range: boolean;
  line_items: EstimatedPriceLineItem[];
  max_expected_price_before_discount_in_cents: number;
  min_expected_price_before_discount_in_cents: number;
  name: string;
  price: number;
  price_in_cents: number;
  suggested_tips: SuggestedTips[];
  walk_type_id: number;
  walk_type_name: string;
} & V4_200_Error;

export type GetEstimatedPriceResponseV4 = AxiosResponse<EstimatedPriceV4>;

export type EstimatedPriceV4QueryParams = {
  _disable_v4_wrapper?: string;
  dog_count: number;
  id: number;
  walker_id?: number;
  walker_uuid?: string;
  include_promo_codes?: number;
  is_recurring?: number;
  day_count?: number;
  start_date: string;
  end_date?: string;
  start_time: string;
  end_time?: string;
  walk_type_id: number;
  has_pickup_and_dropoff?: number;
  fill_strategy?: number;
  overnight_stay_required?: number;
}
