import React from 'react';
import clsx from 'clsx';
type Props = {
  heroDesktopImg?: string;
  heroMobileImg?: string;
};
export const HeroImage = ({
  heroDesktopImg,
  heroMobileImg
}: Props) => <picture data-sentry-element="picture" data-sentry-component="HeroImage" data-sentry-source-file="HeroImage.tsx">
    <source media="(max-width: 700px)" srcSet={heroMobileImg} />
    <img className={clsx('h-[calc(400px+4vw)] sm:h-full w-full object-cover absolute top-0', 'sm:h-[calc(380px+4vw)] md:h-[calc(370px+4vw)] lg:h-[calc(360px+4vw)] xl:h-[calc(350px+3vw+3vh)]', '2xl:h-[calc(250px+10vw+10vh)]')} src={heroDesktopImg} alt="hero" />
  </picture>;