import { App } from '../constants';
import { canUseWindow } from '../wag-react/common/xenv/core';

export const useSegment = () => {
  if (!canUseWindow) {
    return null;
  }

  const windowSegment = window[App.SegmentAnalyticsWindowKey];

  /**
   * Note that there will be times that this function will re-render
   * depending on React itself (based on the component where it is used).
   * And so it is important to know that when this function returns a `null`
   * value it doesn't necessarily means it is really `null`.
   * Most of the time it is just waiting for the external resource to be done.
   *
   * To check if segment has been loaded properly, do a
   * `window.analytics` in the console, and check for the `onerror`
   * and `onload` in the Segment script component
   */
  if (!windowSegment) {
    return null;
  }

  return windowSegment;
};
