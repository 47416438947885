'use client';

import React, { useEffect } from 'react';
import { Description, DescriptionProps } from './description';
import { Hero, LandingHeroProps } from './hero';
import { HowToBook, HowToBookProps } from './how-to-book';
import { LocalExpert, LocalExpertProps } from './local-expert';
import { ServiceReviews } from './reviews';
import { WagApp } from './wag-app';
import { WagSpecial } from './wag-special';
import { WagStates, WagStatesProps } from './wag-states';
import { useSetAttribution } from '../../hooks/useSetAttribution';
import { FAQProps, WagFAQ } from './wag-faq';
import { ServiceDescription, ServiceDescriptionProps } from './service-description';
import { ServiceBenefits, ServiceBenefitsProps } from './service-benefits';
import { ServiceLinks, ServiceLinksProps } from './service-links';
import { PromoModal, PromoModalProps } from '../../../../tw-components/PromoModal';
import { isBefore, parseISO, startOfToday } from 'date-fns';
import { useRouter } from 'next/router';
import { useAuthStore, useBookingStore, useFiltersStore } from '../../context';
import { useResetJourney } from '../../hooks/useResetJourney';
import { initialCaregiverValues } from '../../context/stores/booking-journey';
import { FeatureFlags } from '@/constants/feature-flags';
export type PageServiceTypes = 'Boarding' | 'Sitting' | 'Walking' | 'Drop-In';
type Props = {
  heroProps?: LandingHeroProps;
  howToBookProps: HowToBookProps;
  descriptionProps: DescriptionProps;
  wagStates: WagStatesProps;
  localExpertProps: LocalExpertProps;
  faqProps?: FAQProps;
  serviceBenefits?: ServiceBenefitsProps;
  serviceDescription?: ServiceDescriptionProps;
  promoModalProps?: PromoModalProps;
  serviceLinks?: ServiceLinksProps;
};
export const LandingMain = ({
  heroProps,
  wagStates,
  descriptionProps,
  howToBookProps,
  localExpertProps,
  faqProps,
  serviceDescription,
  serviceBenefits,
  serviceLinks,
  promoModalProps
}: Props) => {
  const router = useRouter();
  const createdAt = useBookingStore(state => state.createdAt);
  const {
    resetBookingJourneyAndClearStorage,
    resetAuthState
  } = useResetJourney();
  const isOnDemand = useBookingStore(state => state.isOnDemand);
  const service = useFiltersStore(state => state.filters.service);
  const setSchedule = useBookingStore(state => state.setSchedule);
  const sessionToken = useAuthStore(state => state.session.sessionToken);
  const setJourney = useBookingStore(state => state.setJourney);
  const setCaregiver = useBookingStore(state => state.setCaregiver);
  const setRecommendedCaregivers = useBookingStore(state => state.setRecommendedCaregivers);
  const setTotalValue = useBookingStore(state => state.setTotalValue);
  const setIsMarketplaceEnabled = useBookingStore(state => state.setIsMarketplaceEnabled);
  useSetAttribution();
  const hasDatePassed = dateString => {
    const inputDate = parseISO(dateString);
    const today = startOfToday();
    return isBefore(inputDate, today);
  };
  useEffect(() => {
    // Always enable marketplace for non-on-demand services
    setIsMarketplaceEnabled(!isOnDemand);
  }, [setIsMarketplaceEnabled, isOnDemand]);
  useEffect(() => {
    // reset recommended caregivers
    setRecommendedCaregivers([]);
    setTotalValue({
      lowestPrice: null,
      highestPrice: null,
      showRange: false
    });
    const checkIfDirectMatch = () => {
      if (!router.query.directMatch || !isOnDemand) return;
      if (sessionToken) {
        setJourney({
          step: 'RequestService'
        });
      } else {
        setJourney({
          step: 'CreateAccount'
        });
      }
      router.push('/on-demand');
    };

    // Reset the caregiver state
    setCaregiver(initialCaregiverValues);
    setSchedule({
      fillStrategy: 1
    });
    checkIfDirectMatch();
  }, [createdAt, isOnDemand, resetAuthState, resetBookingJourneyAndClearStorage, router, sessionToken, setCaregiver, setJourney, setRecommendedCaregivers, setSchedule, setTotalValue]);
  const howToBookFirst = !isOnDemand || !serviceLinks?.showCatLink && !isOnDemand;
  const renderDescription = () => {
    return <Description {...descriptionProps} data-sentry-element="Description" data-sentry-component="renderDescription" data-sentry-source-file="index.tsx" />;
  };
  const renderHowToBook = () => {
    return <HowToBook {...howToBookProps} serviceLinks={!!serviceLinks?.LinkSection || serviceLinks?.showCatLink} isCatSitting={!serviceLinks?.showCatLink} data-sentry-element="HowToBook" data-sentry-component="renderHowToBook" data-sentry-source-file="index.tsx" />;
  };
  return <main data-sentry-component="LandingMain" data-sentry-source-file="index.tsx">
      <Hero heroProps={heroProps} data-sentry-element="Hero" data-sentry-source-file="index.tsx" />
      {promoModalProps && !hasDatePassed(promoModalProps.offerExpiry) && <PromoModal {...promoModalProps} />}
      
      {howToBookFirst ? <>
          {renderHowToBook()}
          {renderDescription()}
        </> : <>
          {renderDescription()}
          {renderHowToBook()}
        </>}
      <ServiceLinks {...serviceLinks} data-sentry-element="ServiceLinks" data-sentry-source-file="index.tsx" />
      <WagSpecial data-sentry-element="WagSpecial" data-sentry-source-file="index.tsx" />
      <LocalExpert {...localExpertProps} data-sentry-element="LocalExpert" data-sentry-source-file="index.tsx" />
      <ServiceDescription {...serviceDescription} data-sentry-element="ServiceDescription" data-sentry-source-file="index.tsx" />
      <ServiceReviews data-sentry-element="ServiceReviews" data-sentry-source-file="index.tsx" />
      {serviceBenefits?.items && <ServiceBenefits {...serviceBenefits} />}
      <WagApp data-sentry-element="WagApp" data-sentry-source-file="index.tsx" />
      {faqProps?.items && <WagFAQ {...faqProps} />}
      <WagStates title={wagStates.title} data-sentry-element="WagStates" data-sentry-source-file="index.tsx" />
    </main>;
};