import clsx from 'clsx';
import React from 'react';
import { AppDownload } from '../../footer/AppDownload';
import { SectionContainer } from '../components/SectionContainer';
import { SectionTitle } from '../components/SectionTitle';
import { AppImage } from './AppImage';
type Props = {
  containerClassName?: string;
};
export const WagApp = ({
  containerClassName
}: Props) => <SectionContainer className={clsx('relative lg:!my-[140px] w-[90%] flex flex-col md:flex-row', 'md:px-[20px] max-w-[1150px] mx-auto', 'md:justify-between lg:justify-around', containerClassName)} data-sentry-element="SectionContainer" data-sentry-component="WagApp" data-sentry-source-file="index.tsx">
    <AppImage imgClassName="top-[104px] md:top-0 mx-auto mb-6 md:mb-0 md:left-0" data-sentry-element="AppImage" data-sentry-source-file="index.tsx" />
    <div className="md:w-[44%] md:flex md:flex-col md:justify-center">
      <SectionTitle text="Pet care at your fingertips" className="mb-4 md:!mb-[20px] md:text-left" data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
      <p className={clsx('pb-[24px] md:pb-0 text-[14px] md:text-[16px] text-center md:text-left text-wrap-balance')}>
        Download the Wag! app for exclusive discounts, faster bookings and even more services.
      </p>
      <p className="hidden md:block mt-4">
        Want to track where your dog’s being walked,
        or find out if they’ve had a poop?
        Or would you just like to message their Pet Caregiver to find out how they’re doing,
        and maybe get some photo and video updates of their day?
      </p>
      <AppDownload containerClassName="md:mt-6" data-sentry-element="AppDownload" data-sentry-source-file="index.tsx" />
      <p className="text-[12px] text-center mt-2 md:w-[320px]">Available on iOS and Android</p>
    </div>

  </SectionContainer>;