import clsx from 'clsx';
import React from 'react';
import { statesData } from '../../../../../data/states';
import EmblaCarousel from '../components/EmblaCarousel';
import { SectionContainer } from '../components/SectionContainer';
import { SectionTitle } from '../components/SectionTitle';
export type WagStatesProps = {
  title: string;
};
type StatesData = typeof statesData;
export const WagStates = ({
  title
}: WagStatesProps) => {
  const n = 5;
  const statesDataMatrix = [[], [], [], [], []];
  const statesPerColumn = Math.ceil(statesData.length / n);
  for (let line = 0; line < n; line += 1) {
    for (let i = 0; i < statesPerColumn; i += 1) {
      const value = statesData[i + line * statesPerColumn];
      if (value) {
        statesDataMatrix[line].push(value);
      }
    }
  }
  return <SectionContainer data-sentry-element="SectionContainer" data-sentry-component="WagStates" data-sentry-source-file="index.tsx">
      <SectionTitle text={title} className="mb-0 md:!mb-[20px]" data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
      <p className="mt-[20px] mb-[6px] md:mb-[60px] text-center">
        Available in 5,300+ cities across all 50 states
      </p>

      <EmblaCarousel emblaDotsContainer="left-[calc(50%-64px)]" data-sentry-element="EmblaCarousel" data-sentry-source-file="index.tsx">
        {statesDataMatrix.map((stateColumn, index) => <div key={String(stateColumn?.[index]?.stateAbbrev)} className={clsx('md:w-full first:ml-[10%] mr-[5%] last:mr-2', 'max-w-[400px] mx-auto min-w-[150px] first:md:ml-0 md:mr-0 md:mx-auto md:last:mr-0')}>
            {stateColumn.map((stateData: StatesData[number]) => <a key={stateData?.stateAbbrev} href={`/${stateData?.stateAbbrev?.toLowerCase()}`} className={clsx('text-center w-fit block mx-auto')}>
                <p className="text-wagBlue text-[14px] mb-1 w-fit hover:underline decoration-wagBlue">
                  {stateData.state}
                </p>
              </a>)}
          </div>)}
      </EmblaCarousel>
    </SectionContainer>;
};