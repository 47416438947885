import React from 'react';
import Link from 'next/link';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/tw-components/ui/accordion';
import { SectionTitle } from '../components/SectionTitle';
import { cn } from '../../../../../utils/tailwind/cn';
import { SectionContainer } from '../components/SectionContainer';
export type FAQItem = {
  question: string;
  answer: string;
  highlight?: boolean;
};
export type FAQProps = {
  items: FAQItem[];
  sectionTitleClassName?: string;
  title?: string;
};
export const WagFAQ = ({
  items,
  title = 'Frequently asked questions',
  sectionTitleClassName
}: FAQProps) => <SectionContainer className="max-w-[850px] w-[90%]" data-sentry-element="SectionContainer" data-sentry-component="WagFAQ" data-sentry-source-file="index.tsx">
    <SectionTitle text={title} className={cn('md:mb-[20px] lg:mb-[38px]', sectionTitleClassName)} data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
    <div className="flex flex-col w-full">
      {items?.map(item => item?.highlight ? <div key={item.question} className="my-4 mt-6 first:mt-0 md:mt-12 md:first:mt-0">
          <h3 className="mt-6 first:mt-0">{item.question}</h3>
          <p className="text-wagGray"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: item.answer
      }} />
        </div> : <Accordion key={item.question} className="w-full" type="single" collapsible>
          <AccordionItem value={item.question} className="border-gray200">
            <AccordionTrigger className={cn('text-darkGray text-[16px] text-left font-[600] md:text-[19px]', 'py-5 md:py-6')}>
              {item.question}
            </AccordionTrigger>
            <AccordionContent className="text-wagGray">
              <p className="text-wagGray"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: item.answer
          }} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>)}
    </div>

    <p className="text-center mx-auto mt-6 text-[16px] md:mt-12">
      Didn&apos;t find the answer?
      {' '}
      Visit our
      {' '}
      <Link href="https://support.wagwalking.com/" aria-label="Visit our FAQ page" className="underline" data-sentry-element="Link" data-sentry-source-file="index.tsx">
        FAQ page
      </Link>

    </p>
  </SectionContainer>;