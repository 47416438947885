'use client';

import clsx from 'clsx';
import React, { useState } from 'react';
import { Play } from 'lucide-react';
import LocalExpertImgMobile from '../../../assets/local-expert-mobile.webp';
import LocalExpertImgDesktop from '../../../assets/pet-care-desktop.webp';
import { cn } from '../../../../../utils/tailwind';
export const CaregiverMediaSection = () => {
  const [showVideo, setShowVideo] = useState(false);
  const handleVideoClick = () => {
    setShowVideo(true);
  };
  return showVideo ? <iframe width="1254" height="705" src="https://www.youtube.com/embed/fsktE7Xi_48?autoplay=1" title="Dog Walking with Wag! Dog Walkers | Wag!" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" id="caregiver-video" allowFullScreen className="absolute bottom-0 right-0 md:top-0 w-full md:w-1/2 h-[35%] md:h-full" data-sentry-component="CaregiverMediaSection" data-sentry-source-file="CaregiverMediaSection.tsx" /> : <picture data-sentry-element="picture" data-sentry-component="CaregiverMediaSection" data-sentry-source-file="CaregiverMediaSection.tsx">
      <div className={cn('bg-wagBlue absolute z-[10] p-4 rounded-full', 'top-[calc(80%-20px)] right-[calc(50%-30px)] md:right-[calc(25%-40px)] md:top-[calc(50%-40px)]', 'w-[60px] h-[60px] md:w-[80px] md:h-[80px] flex items-center justify-center')}>
        <Play className={cn('w-[30px] h-[30px] md:w-[40px] md:h-[40px] cursor-pointer text-white ml-1')} fill="white" onClick={handleVideoClick} data-sentry-element="Play" data-sentry-source-file="CaregiverMediaSection.tsx" />
      </div>
      <source media="(max-width: 700px)" srcSet={LocalExpertImgMobile} />
      <img className={clsx('object-cover absolute bottom-0 right-0', 'w-full h-[400px] sm:h-[580px] md:max-w-[50%]', 'md:h-[590px] md:w-[480px] lg:w-[560px]')} loading="lazy" src={LocalExpertImgDesktop} alt="hero" />
    </picture>;
};