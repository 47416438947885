import React from 'react';
import { Separator } from '../../../../../tw-components/ui/separator';
import { cn } from '../../../../../utils/tailwind/cn';
import { SectionContainer } from '../components/SectionContainer';
import { CatLinks } from './links';
export type ServiceLinksProps = {
  LinkSection?: React.FC;
  showCatLink?: boolean;
};
export const ServiceLinks = ({
  LinkSection = null,
  showCatLink = false
}: ServiceLinksProps) => showCatLink || LinkSection ? <SectionContainer className={cn('w-[90%] lg:mt-[20px]')}>
      {showCatLink && <CatLinks />}

      {showCatLink && LinkSection && <Separator className={cn('mx-auto text-center', 'my-4 md:my-6 lg:my-8 lg:w-[600px]')} />}
      {LinkSection && <LinkSection />}
    </SectionContainer> : null;