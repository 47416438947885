import * as Yup from 'yup';

// https://segment.com/docs/connections/spec/track/

export const segmentTrackSchema = Yup.object({
  event: Yup.string().required(),
  properties: Yup.object({
    name: Yup.string().notRequired(),
    question: Yup.string().notRequired(),
    symptoms: Yup.string().notRequired(),
    age: Yup.string().notRequired(),
    medications: Yup.string().notRequired(),
    breed: Yup.string().notRequired(),
    email: Yup.string().email().notRequired(),
    phone: Yup.string().notRequired(),
    cta_type: Yup.string().notRequired(),
    content_type: Yup.string().notRequired(),
    variant: Yup.string().notRequired(),
    experiment: Yup.string().notRequired(),
    walker_uuid: Yup.string().notRequired().nullable(),
  }).notRequired(),
});

// Nested typing has issues still; might need to adjust tsconfig https://github.com/jquense/yup#typescript-setting
export type SegmentTrackSchema = Yup.InferType<typeof segmentTrackSchema>;
