import clsx from 'clsx';
import React, { ReactNode } from 'react';
type Props = {
  containerClassName?: string;
  children?: ReactNode;
};
export const BookingTagline = ({
  containerClassName,
  children
}: Props) => <p className={clsx('max-w-[400px] md:max-w-[1000px] mx-auto text-[12px] md:text-[14px] lg:text-[16px] text-black md:text-white text-left', containerClassName)} data-sentry-component="BookingTagline" data-sentry-source-file="Tagline.tsx">
    {children}
  </p>;