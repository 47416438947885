'use client';

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { cn } from '../../../../../../utils/tailwind';
type PetPickerProps = {
  icon: string;
  title: string;
  onChange: (count: number) => void;
  max?: number;
  min?: number;
  currentNumber?: number;
  customClassName?: string;
  iconClassName?: string;
  cySelector?: string;
};
const PetPicker: React.FC<PetPickerProps> = ({
  icon,
  title,
  onChange,
  max = 9,
  min = 0,
  currentNumber = 0,
  customClassName,
  iconClassName,
  cySelector
}) => {
  const [count, setCount] = useState(Math.max(min, currentNumber));
  useEffect(() => {
    if (count !== currentNumber) {
      onChange(count);
    }
  }, [count, currentNumber, onChange]);
  const increment = () => {
    if (count < max) {
      setCount(count + 1);
    }
  };
  const decrement = () => {
    if (count > min) {
      setCount(count - 1);
    }
  };
  return <div className={cn('flex items-center justify-between', customClassName)} data-sentry-component="PetPicker" data-sentry-source-file="PetPicker.tsx">
      <div className="flex items-center space-x-3">
        <img src={icon} alt={title} className={cn('h-5 w-5 mr-2', iconClassName)} />
        <span className="font-normal text-gray-700">{title}</span>
      </div>
      <div className="flex items-center space-x-3">
        <button className={clsx('bg-white rounded-full h-8 w-8 flex items-center justify-center border', count === min ? 'text-gray-300 border-gray-300' : 'text-gray-700 border-gray-700')} onClick={decrement} disabled={count === min} data-cy={`${cySelector}-decrement`}>
          -
        </button>
        <span className="font-normal text-gray-700">{count}</span>
        <button className={clsx('bg-white rounded-full h-8 w-8 flex items-center justify-center border', count === max ? 'text-gray-300 border-gray-300' : 'text-gray-700 border-gray-700')} onClick={increment} disabled={count === max} data-cy={`${cySelector}-increment`}>
          +
        </button>
      </div>
    </div>;
};
export default PetPicker;