/* eslint-disable camelcase */
import { useSegment } from './useSegment';
import {
  segmentTrackSchema,
  SegmentTrackSchema,
} from '../utils/validation-schemas/segment/track';

import { App, PaymentTypes } from '../constants/app';

export type BookingJourneySegmentTrackEventName =
| 'Pet Register Completed'
| 'OnDemand Add Schedule Completed'
| 'Add Schedule Completed'
| 'Add Payment Method Started'
| 'Payment Method Added'
| 'Payment Method Failure'
| 'Booking Request Started'
| 'Booking Request Failed'
| 'Booking Request Completed'
| 'Promo Code Added'
| 'Promo Code Failure'
| 'Successful Submission'
| 'Add Schedule Successful Submission'
| 'Error Ocurred';

export type BookingJourneySegmentTrack = {
  event: string;
  properties: {
    walker_id?: string;
    walker_uuid?: string;
    booking_request_id?: string;
    service_name?: string;
    service_category?: string;
    startDate?: string;
    endDate?: string;
    pet_name?: string;
    breed?: string;
    age?: string;
    gender?: string;
    sterile?: string;
    weight?: string;
    reason?: string;
    promoCode?: string;
    paymentMethod?: PaymentTypes;
    scheduleId?: string;
    ownerId?: string;
  };
}

export type BookingSegmentTrack = {
  event:
    | 'Pet Info Completed'
    | 'Service Selected'
    | 'Timeframe Selected'
    | 'Service Confirmed'
    | 'Validation Error'
    | 'Booking Started'
    | 'Add Payment Method Started'
    | 'Payment Method Added Successfully'
    | 'Payment Method Failure'
    | 'Booking Request Started'
    | 'Booking Request Failed';
  properties: {
    walker_id?: string;
    walker_uuid?: string;
    booking_request_id?: string;
    service_name?: string;
    service_category?: string;
    asap?: boolean;
    scheduled?: boolean;
    pet_name?: string;
    breed?: string;
    age?: number;
    gender?: string;
    sterile?: string;
    weight?: string;
    reason?: string;
    paymentMethod?: PaymentTypes;
  };
}

export const useSegmentTrack = () => {
  const segment = useSegment();

  /**
   * @deprecated
   *
   * This execution function is not 100% accurate
   * and still depends on the component to be re-rendered
   * which will result to inconsistent segment value.
   * Please use `executeTrack` to handle the up-to-date
   * segment value
   */
  const execute = async ({
    data,
  }: {
    data: SegmentTrackSchema;
  }) => {
    const {
      event,
      properties,
    } = await segmentTrackSchema.validate(data);

    segment.track(event, properties);
  };

  const executeTrack = async <T = void>({
    data,
  }: {
    data: T extends void ? SegmentTrackSchema : T;
  }) => {
    let segmentInstance;

    if (typeof window !== 'undefined') {
      segmentInstance = window[App.SegmentAnalyticsWindowKey];
    }

    if (!segmentInstance) {
      return;
    }

    const {
      event,
      properties,
    } = await segmentTrackSchema.validate(data);
    segmentInstance.track(event, properties);
  };

  return {
    execute,
    executeTrack,
  };
};

export type ExecuteSegmentTrack = ReturnType<typeof useSegmentTrack>;
