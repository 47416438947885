'use client';

import React from 'react';
import { cn } from '../../../../../utils/tailwind/cn';
import { IS_BROWSER } from '../../BoneSpinner';
import { SectionContainer } from '../components/SectionContainer';
import { SectionTitle } from '../components/SectionTitle';
import { FindCaregiverButton } from '../hero/FindCaregiverButton';
export type ServiceBenefitsProps = {
  items: {
    title: string;
    image: string;
    Description: React.FC;
  }[];
};
export const ServiceBenefits = ({
  items
}: ServiceBenefitsProps) => <SectionContainer className="flex flex-col w-[90%] md:w-full space-y-10 md:space-y-[50px] max-w-[1150px]" data-sentry-element="SectionContainer" data-sentry-component="ServiceBenefits" data-sentry-source-file="index.tsx">
    {items.map(({
    title,
    image,
    Description
  }, i) => <div className={cn('flex flex-col', i % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row', 'md:justify-around')} key={title}>
        <img src={image} alt={title} className="w-full mb-6 md:w-[44%]" />
        <div className="md:w-[44%] md:flex md:flex-col md:justify-center">
          <SectionTitle text={title} className="mb-4 md:!mb-[20px] md:text-left" />
          <Description />
        </div>
      </div>)}
    <FindCaregiverButton containerClassName={cn('py-4 w-[300px]')} tooltipTriggerClassName="mx-auto w-[80%] max-w-[300px] text-center" onClick={() => {
    const bookingFilters = document.querySelector<HTMLDivElement>('#booking-filters');
    if (bookingFilters && IS_BROWSER) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      const modalTriggerButton = document.querySelector<HTMLButtonElement>('#filters-modal-trigger');
      if (modalTriggerButton) {
        modalTriggerButton.click();
      }
    }
  }} data-sentry-element="FindCaregiverButton" data-sentry-source-file="index.tsx" />
  </SectionContainer>;