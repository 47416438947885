'use client';

import React, { useEffect, useRef, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../../tw-components/ui/popover';
import { TWButton } from '../../../../../../tw-components/ui/button';
import { useFiltersStore } from '../../../../context';
import { cn } from '../../../../../../utils/tailwind';
import clsx from 'clsx';
import { petType } from '../data';
import PetPicker from './PetPicker';
import { PawPrintOutlinedIcon } from '@/icons/PawPrintOutlinedIcon';
import { getPetText } from '@/wag-react/common/utils/getPetsText';
type Props = {
  triggerButtonId?: string;
  iconColor?: string;
  dateCaptionClassName?: string;
  triggerButtonClass?: string;
  noPetsTitle?: string;
};
export const PetsPicker = ({
  triggerButtonId = 'pets-filter-btn',
  iconColor = '#00BF8F',
  dateCaptionClassName = '',
  triggerButtonClass = '',
  noPetsTitle = 'No Pets Added'
}: Props) => {
  const setSearch = useFiltersStore(state => state.setSearch);
  const setFilters = useFiltersStore(state => state.setFilters);
  const {
    dogsNumber,
    catsNumber
  } = useFiltersStore(state => state.filters);
  const triggerButtonRef = useRef(null);
  const [dog = null, cat = null] = Array.isArray(petType) ? petType : [];
  const [title, setTitle] = useState(noPetsTitle);
  const [totalPetsSelected, setTotalPetsSelected] = useState(0);
  const handleDogChange = (count: number) => {
    setFilters({
      dogsNumber: count
    });
  };
  const handleCatChange = (count: number) => {
    setFilters({
      catsNumber: count
    });
  };
  useEffect(() => {
    const newTotalPets = (dogsNumber || 0) + (catsNumber || 0);
    setTotalPetsSelected(newTotalPets);
    if (newTotalPets === 0) {
      setTitle(noPetsTitle);
    } else {
      const dogText = getPetText(dogsNumber, 'Dog');
      const catText = getPetText(catsNumber, 'Cat');
      const titleValue = [dogText, catText].filter(Boolean).join(', ');
      setTitle(titleValue);
    }
  }, [dogsNumber, catsNumber, noPetsTitle]);
  return <Popover onOpenChange={isOpen => {
    setSearch({
      enabled: !isOpen
    });
  }} data-sentry-element="Popover" data-sentry-component="PetsPicker" data-sentry-source-file="PetsPicker.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="PetsPicker.tsx">
        <TWButton ref={triggerButtonRef} id={triggerButtonId} size="lg" className={clsx('justify-start text-wagGray text-left w-full bg-white pl-0', 'focus-visible:outline outline-1 outline-caribbeanGreen', 'border-gray200', 'text-muted-foreground', triggerButtonClass)} data-sentry-element="TWButton" data-sentry-source-file="PetsPicker.tsx">
          <PawPrintOutlinedIcon color={iconColor} className="mr-2" data-sentry-element="PawPrintOutlinedIcon" data-sentry-source-file="PetsPicker.tsx" />
          <span className={cn('text-[12px] font-new-hero text-darkGray mt-0.5 font-[400]', totalPetsSelected == 0 ? 'opacity-50' : '', dateCaptionClassName)}>
            {title}
          </span>
        </TWButton>
      </PopoverTrigger>
      <PopoverContent className="!px-5 !py-6 flex flex-col items-center" align="center" data-sentry-element="PopoverContent" data-sentry-source-file="PetsPicker.tsx"> 
        <div className="bg-white rounded-lg w-60">
          {dog?.icon && <PetPicker icon={dog.icon} title="Dogs" onChange={handleDogChange} currentNumber={dogsNumber} customClassName="mb-5" cySelector="dog-picker" />}
          {cat?.icon && <PetPicker icon={cat.icon} title="Cats" onChange={handleCatChange} currentNumber={catsNumber} cySelector="cat-picker" />}
        </div>
      </PopoverContent>
    </Popover>;
};