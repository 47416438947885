import {
  initialAccessDetails,
  initialSchedule,
  useAuthStore,
  useBookingStore,
} from '../context';
import { initialCaregiverValues, initialOwnerValues, initialPetValues } from '../context/stores/booking-journey';

export const useResetJourney = () => {
  const setPet = useBookingStore((state) => state.setPet);
  const setCaregiver = useBookingStore((state) => state.setCaregiver);
  const setOwner = useBookingStore((state) => state.setOwner);
  const setJourney = useBookingStore((state) => state.setJourney);
  const setAccessDetails = useBookingStore((state) => state.setAccessDetails);
  const setSchedule = useBookingStore((state) => state.setSchedule);
  const setSelectedPets = useBookingStore((state) => state.setSelectedPets);
  const setCreatedAt = useBookingStore((state) => state.setCreatedAt);

  const setSession = useAuthStore((state) => state.setSession);

  const resetStore = useBookingStore((state) => state.resetStore);

  const isOnDemand = useBookingStore((state) => state.isOnDemand);

  const resetJourney = () => {
    setSchedule(initialSchedule);
    setOwner(initialOwnerValues);
    setAccessDetails(initialAccessDetails);
    setPet(initialPetValues);
    setSelectedPets({});
    setCreatedAt(Date.now());
  };

  const resetBrowseAndBook = () => {
    setJourney({ step: 'Profile' });
    setCaregiver(initialCaregiverValues);
    setPet(initialPetValues);
    setSelectedPets({});
    setPet(initialPetValues);
    setSchedule(initialSchedule);
  };

  const resetBookingJourney = () => {
    resetJourney();
    if (isOnDemand) {
      setSchedule({
        fillStrategy: 1,
      });
    } else {
      setSchedule({
        fillStrategy: 5,
      });
    }
    setTimeout(() => {
      setJourney({ step: 'SignIn' });
    }, 50);
  };

  const resetSensitiveData = () => {
    if (!initialOwnerValues.phone) return;
    setAccessDetails(initialAccessDetails);
    setOwner({
      phone: initialOwnerValues.phone,
    });
  };

  const resetBookingJourneyAndClearStorage = () => {
    useBookingStore.persist.clearStorage();
    resetStore(useBookingStore.getState());
  };

  const resetAuthState = () => {
    setSession({
      accessToken: null,
      sessionToken: null,
      refreshToken: null,
      socialProvider: null,
    });
  };

  return {
    resetBrowseAndBook,
    resetBookingJourneyAndClearStorage,
    resetAuthState,
    resetBookingJourney,
    resetSensitiveData,
  };
};
