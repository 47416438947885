'use client';

import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { useTimeout } from '../../hooks/useTimeout';
import { cn } from '../../utils/tailwind';
import { Dialog, DialogContent } from '../ui/dialog';
import { PromoModalContentProps } from '../../transformers/promo-modal-transformer';
const PromoModalContent = dynamic(() => import('./PromoModalContent').then(mod => mod.PromoModalContent), {
  ssr: false
});
export type PromoModalProps = Omit<PromoModalContentProps, 'setIsOpen'>;
export const PromoModal = ({
  ...promoModalProps
}: PromoModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasRendered, setHasRendered] = useState(false);
  const {
    msBeforeShow = 3000
  } = promoModalProps;
  useTimeout(() => {
    if (hasRendered) return;
    setHasRendered(true);
    setIsOpen(true);
  }, msBeforeShow);
  return <Dialog open={isOpen} onOpenChange={open => {
    setIsOpen(open);
  }} data-sentry-element="Dialog" data-sentry-component="PromoModal" data-sentry-source-file="index.tsx">
      <DialogContent overlayClassName="z-[1100]" closeButtonClassName={cn('bg-white mr-2 mt-2 rounded-full w-[24px] h-[24px] md:w-[30px] md:h-[30px]', 'flex items-center justify-center p-0', 'border-0 cursor-pointer')} closeButtonIconClassName="text-black stroke-[4px] border-0 !w-4 !h-4 md:!w-6 md:!h-6" className={cn('z-[1100] max-w-[700px] rounded-2xl', 'p-5 md:!p-6 max-sm:pb-24 max-sm:max-h-max max-sm:w-max md:w-[800px] lg:w-[900px] md:h-[500px] lg:min-h-[500px] xl-w-[1200px] max-w-screen', 'bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))]', 'bg-backgroundGray', 'box-border shadow-inner',
    // Inner border with rounded edges using box shadow
    'rounded-2xl shadow-[inset_0_0_0_10px_white]')} data-sentry-element="DialogContent" data-sentry-source-file="index.tsx">
        <PromoModalContent setIsOpen={setIsOpen} {...promoModalProps} data-sentry-element="PromoModalContent" data-sentry-source-file="index.tsx" />
      </DialogContent>
    </Dialog>;
};