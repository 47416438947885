import AvailableIcon from '../../../../assets/available.svg';
import GPSIcon from '../../../../assets/gps.svg';
import SolarIcon from '../../../../assets/solar.svg';
import ShieldIcon from '../../../../assets/shield.svg';

export const wagSpecialItems = [
  {
    title: 'We’re always available',
    description: 'Whatever the time, we’re always here for you and your dog. Whether you’re going on vacation, stuck at work, or looking to book daily walks, we’ve got you covered!',
    icon: AvailableIcon,
  },
  {
    title: 'We make your life easier!',
    description: 'With GPS-tracked walks, customized lockboxes, and in-app messaging, we’ve thought of everything to make the process of getting a pet boarder as easy as possible.',
    icon: GPSIcon,
  },
  {
    title: 'We take safety seriously',
    description: 'Nothing is more important than your dog’s safety – that’s why every Wag! Pet Caregiver has passed an enhanced background check. Our services are also fully insured.',
    icon: SolarIcon,
  },
  {
    title: 'We’ve been doing this for years',
    description: 'Having delivered over 12.1 million pet care services across more than 5,300 cities, and with over 450,000 Pet Caregivers on our books, Wag! is hugely trusted.',
    icon: ShieldIcon,
  },
];
