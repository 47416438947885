import React from 'react';
import { SectionContainer } from '../components/SectionContainer';
import { SectionTitle } from '../components/SectionTitle';
import { wagSpecialItems as wagDefaultSpecialItems } from './data';
export const WagSpecial = ({
  wagSpecialItems = wagDefaultSpecialItems
}) => <SectionContainer data-sentry-element="SectionContainer" data-sentry-component="WagSpecial" data-sentry-source-file="index.tsx">
    <SectionTitle text="Why you should choose Wag!" data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
    <div className="flex flex-wrap justify-between w-[90%] md:w-[94%] lg:w-full mx-auto">
      {wagSpecialItems.map(item => <div key={item.title} className="flex w-full md:w-[47%] mb-8 last:mb-0 lg:mb-12 md:[&:nth-child(3)]:!mb-0 md:[&:nth-child(4)]:!mb-0">
          <img src={item.icon} className="w-[34px] h-[34px] mr-4" alt={item.title} />
          <div>
            <h4 className="mb-2 text-darkGray">{item.title}</h4>
            <p className="text-wagGray">{item.description}</p>
          </div>
        </div>)}
    </div>
  </SectionContainer>;