/**
 * Generates a text string describing the number of pets.
 * 
 * @param count - The number of pets. Must be a non-negative integer.
 * @param pet - The type of pet (e.g., "Dog", "Cat"). Should be capitalized.
 * @returns A string describing the number of pets, or an empty string if count is 0.
 */
export const getPetText = (count: number, pet: string): string => {
  if (!count || !pet) return '';
  return `${count} ${pet}${count > 1 ? 's' : ''}`;
};
